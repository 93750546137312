<template>
  <div>
    <div class="leftcontHead">
      <div class="name">收货地址</div>
      <div class="addBut" @click="dzShowJs()">添加地址</div>
    </div>
    <div class="content">
      <div class="contType">
        <div class="txt w10">收货人</div>
        <div class="txt w10">联系方式</div>
        <div class="txt w30">所在地区</div>
        <div class="txt w30">详细地址</div>
        <div class="txt w20">操作</div>
      </div>
      <div class="contList">
        <Kong v-if="list.length == 0" />
        <div class="contLi" v-for="(item, index) in list">
          <div class="txt w10">{{ item.name }}</div>
          <div class="txt w10">{{ item.mobile }}</div>
          <div class="txt w30">
            {{ item.province }} {{ item.city }} {{ item.area }}
          </div>
          <div class="txt w30">{{ item.address }}</div>
          <div class="txt w20">
            <div class="text" @click="dzShowJs(item.id)">修改</div>
            <a-popconfirm
              title="是否确定删除此地址"
              ok-text="是"
              cancel-text="否"
              @confirm="delConfirm(item, index)"
            >
              <div class="text">删除</div>
            </a-popconfirm>
            <div class="text red" v-if="item.is_default == 1">默认</div>
            <a-popconfirm
              v-if="item.is_default == 0"
              title="是否确定设为默认"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(item, index)"
            >
              <div class="text">设为默认</div>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <TjDizhi v-if="dzshow" :id="addid" @show="dzShowDelJs" />
  </div>
</template>

<script>
import Kong from "../component/kong.vue";
import TjDizhi from "../component/tjDizhi.vue";
import { Popconfirm } from "ant-design-vue";

export default {
  components: {
    TjDizhi,
    Kong,
    "a-popconfirm": Popconfirm,
  },
  name: "",
  data() {
    return {
      dzshow: false,
      typeOn: 0,
      typeList: ["全部", "待付款", "待发货", "待收货", "待评价", "已退款"],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      addid: "",
    };
  },
  created() {
    this.listGet();
  },
  methods: {
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/address", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    dzShowJs(id) {
      this.addid = id;
      this.dzshow = true;
    },
    dzShowDelJs(data) {
      console.log(data);
      this.dzshow = false;
      if (data) {
        this.page.pageNo = 1;
        this.list = [];
        this.listGet();
      }
    },
    confirm(e, i) {
      this.$post("/web/address_default_update", {
        is_default: 1,
        id: e.id,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("修改成功");
          this.page.pageNo = 1;
          this.list = [];
          this.listGet();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delConfirm(e, i) {
      this.$post("/web/delete", {
        table: "address",
        id: e.id,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("上传成功");
          this.page.pageNo = 1;
          this.list = [];
          this.listGet();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    ltjump() {
      this.$router.push({
        path: "/wzCont",
        query: {},
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.leftcontHead {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 37px;
  background-color: #fff;
  border-radius: 5px;
  .name {
    font-weight: 600;
    font-size: 20px;
  }
  .addBut {
    height: 32px;
    width: 90px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #dadde0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #11192d;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.content {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  .contType {
    height: 50px;
    background-color: #f3f6f8;
    margin-top: 10px;
    display: flex;
    box-sizing: border-box;
    .txt {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
  }
  .contList {
    background-color: #fff;
    .contLi {
      height: 50px;
      display: flex;
      box-sizing: border-box;
      border-bottom: 1px solid #f3f3f3;
      .txt {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 12px;
        color: #333;
        .text {
          margin-right: 10px;
          cursor: pointer;
        }
        .red {
          color: #f32004;
        }
      }
    }
    .contLi:last-child {
      border-bottom: none;
    }
  }
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}
</style>
